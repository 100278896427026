var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tab-item',[(_vm.e.publicComment)?_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v("Bemerkungen")])]),_c('v-card-text',{domProps:{"innerHTML":_vm._f("nl2br")(_vm.e.publicComment)}})],1):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.teams,"headers":[
          { text: 'Platz', value: 'place', sortable: false },
          { text: 'Verein', value: 'club.name', sortable: false },
          { text: 'Turner', value: 'athletes', sortable: false },
          ...(_vm.e.status !== 'EventFinished' ? [
            { text: 'Gruppe', value: 'group', sortable: false },
            { text: 'Startgerät', value: 'startdiscipline.name', sortable: false }
          ] : [
            ..._vm.d.map(di => ({ text: di.shortName, value: di._id, sortable: false })),
            { text: 'Gesamt', value: 'final', sortable: false },
            { text: 'Urkunde', value: 'pdf', sortable: false }
          ])
        ],"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":"","sort-by":['place','group','startdiscipline.name','club.name']},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.place))]),_c('td',[_vm._v(_vm._s(item.club.name))]),_c('td',[_vm._l((item.athletes),function(a,i){return [(i > 0)?_c('br',{key:i}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("person")(a))+" ")]})],2),(_vm.e.status !== 'EventFinished')?[_c('td',[_vm._v(_vm._s(item.group))]),_c('td',[_vm._v(_vm._s(item.startdiscipline.name))])]:[_vm._l((_vm.d),function(d){return _c('td',{key:d._discipline},[_vm._v(_vm._s(_vm._f("float2")(item[d._discipline])))])}),_c('td',{staticStyle:{"font-size":"120%","font-weight":"bold"}},[_vm._v(_vm._s(_vm._f("float2_0")(item.final)))]),_c('td',[_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.pdf(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("far fa-download")])],1)],1)]],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }