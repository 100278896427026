<template>
  <v-tab-item>
    <v-card v-if="e.publicComment">
      <v-card-title><h1>Bemerkungen</h1></v-card-title>
      <v-card-text :inner-html.prop="e.publicComment | nl2br">
      </v-card-text>
    </v-card>
    <v-data-table
      :items="teams"
      :headers="[
            { text: 'Platz', value: 'place', sortable: false },
            { text: 'Verein', value: 'club.name', sortable: false },
            { text: 'Turner', value: 'athletes', sortable: false },
            ...(e.status !== 'EventFinished' ? [
              { text: 'Gruppe', value: 'group', sortable: false },
              { text: 'Startgerät', value: 'startdiscipline.name', sortable: false }
            ] : [
              ...d.map(di => ({ text: di.shortName, value: di._id, sortable: false })),
              { text: 'Gesamt', value: 'final', sortable: false },
              { text: 'Urkunde', value: 'pdf', sortable: false }
            ])
          ]"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      :sort-by="['place','group','startdiscipline.name','club.name']"
    >
      <template #item="{item}">
        <tr>
          <td>{{ item.place }}</td>
          <td>{{ item.club.name}}</td>
          <td>
            <template v-for="(a,i) in item.athletes">
              <br v-if="i > 0" :key="i" />
              {{ a | person }}
            </template>
          </td>
          <template v-if="e.status !== 'EventFinished'">
            <td>{{ item.group }}</td>
            <td>{{ item.startdiscipline.name }}</td>
          </template>
          <template v-else>
            <td v-for="d in d" :key="d._discipline">{{ item[d._discipline] | float2 }}</td>
            <td style="font-size:120%;font-weight:bold;">{{ item.final | float2_0 }}</td>
            <td><v-btn fab small text @click.stop="pdf(item)"><v-icon small>far fa-download</v-icon></v-btn></td>
          </template>
        </tr>
      </template>
      <!--template #item.athletes="{item}">
        <template v-for="(a,i) in item.athletes">
          <br v-if="i > 0" :key="i" />
          {{ a | person }}
        </template>
      </template>
      <template #item.final="{item}">
        {{ item }}
      </template>
      <template #item.pdf="{item}">
        <v-btn fab small text @click.stop="pdf(item)"><v-icon small>far fa-download</v-icon></v-btn>
      </template-->
    </v-data-table>
  </v-tab-item>
</template>

<script>
import { useCalc } from '@/views/components/stb_ltf_2024/plugins/calc'
import gql from 'graphql-tag'
import { deleteQuery, updateQuery } from '@/plugins/graphql'
import Vue from 'vue'
import { usePdf } from '@/views/components/stb_ltf_2024/plugins/pdf'

export const resultquery = `
  _id
  ... on StbLtf2024TcTeamAthleteResult {
    _event teamId _person _discipline
    subpart
    dscore escore
    e1 e2 deduction
    penalty
    final
  }
`
export default {
  name: 'SingleTable',

  setup (_, context) {
    return {
      ...useCalc(),
      ...usePdf(context)
    }
  },

  props: {
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    ResultFind: []
  }),

  computed: {
    d () {
      return this.e.disciplines?.slice()?.sort((a, b) => a.order < b.order ? -1 : 1)?.map(d => ({
        ...d,
        ...this.df.find(d2 => d2._id === d._discipline)
      })) || []
    },
    teams () {
      return this.e.teams.map(t => ({
        ...t,
        ...this.d.reduce((acc, curr) => {
          acc[curr._discipline] = this.mannschaftgeraetergebnis(this.e, this.ResultFind, curr._discipline, `${t.teamId}`)
          return acc
        }, {}),
        final: this.mannschaftergebnis(this.e, this.ResultFind, `${t.teamId}`)
      })).sort((a, b) => a.final > b.final ? -1 : 1).map((t, _, arr) => ({
        ...t,
        place: arr.filter(t2 => t2.final > t.final).length + 1
      }))
    }
  },

  methods: {
    async pdf (item) {
      await this.urkunde(this.e, this.ResultFind, `${item.teamId}`, true)
    }
  },

  apollo: {
    ResultFind: {
      query: gql`query($event: UUID!) {
        ResultFind(event: $event) { ${resultquery} }
      }`,
      variables () {
        return {
          event: this.e._id
        }
      },
      subscribeToMore: {
        document: gql`subscription($event: UUID!) {
          ResultCreate(event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            event: this.e._id
          }
        },
        updateQuery: updateQuery('ResultFind', 'ResultCreate')
      }
    },
    $subscribe: {
      ResultUpdate: {
        query: gql`subscription($event: UUID!) {
          ResultUpdate(event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            event: this.e._id
          }
        },
        result ({ data }) {
          Vue.set(this.ResultFind, this.ResultFind.findIndex(r => r._id === data.ResultUpdate._id), data.ResultUpdate)
        }
      },
      ResultDelete: {
        query: gql`subscription($event: UUID) {
          ResultDelete(event: $event)
        }`,
        variables () {
          return {
            event: this.e._id
          }
        },
        result (id) {
          deleteQuery('ResultFind', 'ResultDelete', this.ResultFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
